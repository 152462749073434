export default {
  0: [
    "That night we first met, I kept asking myself one question: who is this girl? There was something about you…the grin on your face, your laugh, the way you tilted your head when you looked up at me. I didn’t know who you were or what it was, but I couldn’t get enough of it. You sucked me into your presence in a way that I hadn’t felt about someone before. It went from talking about your nose piercing, to me telling the story of taking beer shots in high school, to exchanging goofy photos of ourselves, to the proposition of getting Taco Bell at the end of night. Let’s be honest, did we think it would end at Taco Bell? It never even started at Taco Bell…",
    "But you knew about me from before that night. And your friends knew what was happening to. They would take these photos of us and I wouldn’t take them seriously. Now I treasure these first few moments of innocence. We had little idea of what was to come but went along with it. Our conversations unfolded into deeper layers about one another as the gossip sessions turned into FaceTime calls that summer. I felt a budding sense of curiosity as I would wait for your text during the day and our calls later that night. I wanted to learn more about you in all the ways I could. I didn’t realize it then, but it was obvious now that I was starting to like…like-like you.",
    "June 8th, your birthday, you confessed your feelings and I confessed my own. Two weeks later, I booked a flight to Dallas to come see you. Crazy! Things started to really move, but at the same time they were filled with suspense. We couldn’t wait to see each other, but we were seeing each other in an entirely new light of our relationship. As we sat in anticipation of what was to come, we exchanged our share of ideas for what it might be. I might’ve spoiled the surprise *shrugs*.",
  ],
  1: [
    "And I really did it, I flew to Dallas and asked you to be my girlfriend. But, strangely enough, that wasn’t even the highlight of the trip, it was everything else. Like: getting gifted Reese’s cups and brownies when I arrived; walking together to the Goldman Sachs office and me posting up at WeWork for your sneaky coffee visits; enjoying the rooftop pool of your apartment; dealing with that slightly over-the-top racist Uber driver; picnicking in the Botanical Gardens; visiting the aquarium; taking shots of Soju together on our sushi date; and staying in for dinner to watch Annabelle. Is that even everything? Definitely not. You made that trip all that I could have ever wanted, and I must have convinced you the same.",
    "Three weeks later, you flew to San Francisco for my birthday. Once again, crazy! Even though we only had the weekend, we made the absolute most of it with our trip to Big Sur, the fancy Italian lunch in Carmel Beach, and quite the party at the end where our worlds of friends collided. Our dynamic as a relationship was blossoming as we got a second taste of what it would be like in-person. ",
    "You made my birth month and especially my birthday a moment in time to cherish. I’m proud about how we matured even with the distance over the summer; these leaps to see one another made it memorable. I think a lot about July: my life was already so different than what it had been just a few months ago, and it was exciting. Saying goodbye to you the second time around when you left San Francisco was just as hard, if not harder, than the first time when I departed from Dallas. Calling you became a daily practice as I counted down the days that we would see each other again on campus.",
  ],
  2: [
    "It feels like the first day we arrived on campus was only yesterday. It was our first month in-person together but also it was an odd return to form. We spent our first few moments back in May at UIUC, but so much had changed from the summer that we were seeing campus through an entirely new lens. Even walking down Green St for the first time back felt like a brand new experience as we ran into each others friends and became a “campus couple” (is that a thing? I think it makes sense).",
    "Going to the bars with you for the first time was an entirely new version of fun. I knew I would always have you by my side and live the college experience with you. The weather was another part to cherish because we wouldn’t get it later that semester. We could walk to wherever we wanted whenever we wanted, and took those stupid pictures with Grainger Bob because why not. I think we really started to forfeit the need to impress one another and we could just be ourselves.",
    "One of the highlights of that month was our Bread Co date. I started to realize how much of a food junkie you were and we absolutely balled out. It kicked off the trend of ordering more food than we could consume. I pretended it was our first date and we didn’t know each other, so I could ask dumb questions about your pet peeves and which TV shows were your favorite. The date was a new version of learning about one another, and I realized the energy we shared would make any moment together enjoyable.",
  ],
  3: [
    "September to October was the month of celebrations. We were both well adjusted to one another’s friends groups that we would just attend everything together. Especially around your friends, you always made me feel so comfortable and I really started to love them as much as I had hoped to. I could tell you felt the same way about my friends and it was satisfying to know we both felt comfortable in each others environments.",
    "Garba was a standout moment because it was the first time we got to do something rooted in our culture. I loved seeing you in your lehenga, and that picture we have with you in your dress and myself in the kurta is one of my favorite pics that doesn’t get brought up enough. That night, I remember dancing with your friends, my junior friend group, and all my sophomore friends as well. When I left, seeing you sitting with all of the Cuts was so wholesome, and I couldn’t ask for a better companion to all my companions.",
    "I also started bringing my tubs of rajma chawal and we would eat it together on the couch. And that’s when you showed me avakaya! Once I got used to the kick, that spice combination become my favorite addition to my favorite homecooked dish. I believe it just speaks to how much we both embraced, shared, and appreciated our culture with one another.",
  ],
  4: [
    "By the fourth month, we had really set into our schedules, and life with you felt ~normal~. Not that it was ever un-normal, but I felt accustomed to your presence and a deep sense of satisfaction that had only just begin to realize itself.",
    "The spontaneous Chick-Fil-A runs, last-minute Halloween shopping, and our nighttime brushing-to-boroline ritual were random flavors that just became a part of life. Cliffy became a regular entertainer and a fight for who he loved more (you obviously, I wasn’t helping by chucking him up in the air). But my favorite moment out of all this was our Sakanaya trip where we wore the clothes of the night that we first met and had a sushi date like we did the day I asked you out. It was nice to think about how many more reasons I’d come to like you since then.",
    "For all the more things we did this month, we took it with a stride knowing that the other would be there with us. It became the new default and I felt like you were an extension to myself. We even started going to the gym together! You and I continued to make the most of our time on campus, and it was at this point the semester started to really feel it was flying by.",
  ],
  5: [
    "Our last month on campus together might have been one of my favorite months with you that semester. I remember running to the door to your place in excitement on the day of the first snowfall. It was locked, and usually I’d be anxious to go inside, but I pulled you outside instead with me. Your face lit up so spectacularly at the sight of the snow, and part of me realized I had begun to love the winter so much because of you. The new weather meant we weren’t compelled to go outside as much anymore. Instead we found an even greater appreciation for the activities we did together at home.",
    "There were days we would just spend the entirety of it together. You taught me how to play Reds and it became one of our new favorite games to play. I did my best attempt at teaching Sweep, but I liked the rules we made up better. And we can’t forget about random shows like The Mole and Love Island which became the most fun to watch with you, the reality TV connoisseur. Our homebody lifestyles still invited a great deal of spontaneity whether it was playing Kemps or spoons or watching John Oliver. I was more excited for the winter than I ever had been in my life because of you.",
    "The Chicago trip was the cherry on top. The drive up, Nando’s, playing Reds at the Starbucks Reserve, touring the city with a pit stop at the IBM office, taking you to my “spot” on the rooftop above Millenium Park, cutting the line into the Christkindlmarket: I couldn’t have asked for a better partner for this trip. You made me love my home city all over again, and I was honored to be the reason you could love it yourself.",
  ],
  6: [
    "And here we are, the final chapter in this six-month journey together. Right after the Chicago trip, we proceeded to almost die from self-induced cookie dough food poisoning. The nausea, the pain, the suffering: those 24 hours were only made better knowing you were right there by my side (well, also vomiting and suffering, but by my side still). Our friends were kind enough to drop off care packages like tomato soup, pepto-bismol, and some surprisingly god-tier grapes. Both of our bodies went through a full laundry cycle that day and, in a way, it was a bonding experience like no other.",
    "A week later, it was winter break. You finally got to have BoMo’s in Chicago downtown and experience the city’s nightlife like I never had before (give me a few more months, 21 is just around the corner). In the meantime, I got my teeth yanked out so even if I wasn’t old enough for the clubs, I could still look the part as a grandpa. Dentures coming soon. Like the summer, this time apart has moved both fast and slow. Before I knew it, you were off to South Africa, living a new life and meeting some dope people!",
    "So, where does this history put us today? With all of it in consideration, from beginning to end, I’d like to think we’ve come a long-long way from that night we first met. You have been an important part of my life each and every day that I’ve known you. From that first week in May, to the summer, to the semester we just completed, we’ve grown together and individually. For all the time that we’ve spent together, it’s made this separation over the break all the more challenging. Coming with it are feelings of longing but also endurance. We work through this distance and these last six months have given me every reason to keep thinking and caring about you from afar.",
    "I am counting down the days to when I get to see you again, and I can’t wait to see what this next semester has in store for us. I believe the changes that we encounter will give us newfound ways to love one another. And you have made that part all the more fulfilling for me. Thank you for being a part of my life, Bhavana, and thank you for being the best partner I could have ever asked for.",
  ],
};
